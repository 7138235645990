import { Grid, Typography } from '@mui/material';
import { hatmBlue } from '../theme/hatmPalette';
import '../css/App.css';
import logo from '../graphics/hatm.png'

export default function Home() {
  const getLogo = (): JSX.Element => {
    return (
      <>
        <img src={logo} alt="logo" height={400} />
        <Typography textAlign={"center"}>
          <h1 style={{ padding: 0, margin: 0, color: hatmBlue }}>Mobile Dog Grooming</h1>
        </Typography>
      </>
    );
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ height: "100%" }}>
      <Grid xs={4} item >
        {getLogo()}
      </Grid>
    </Grid>
  );
}