import { Card, Grid, Typography } from "@mui/material";
import poodle from '../graphics/hatm-poodle-cloud.png';

export default function Contact(): JSX.Element {
    return (
        <Grid container justifyContent={"center"} alignContent={"center"} gap={3} paddingTop={'1rem'}>
            <Grid item>
                <Card
                    elevation={5}
                    style={{
                        borderRadius: "20px",
                        maxWidth: "550px",
                        padding: "20px 30px",
                        margin: "0 auto",
                    }}>
                    <Grid container
                        direction="row"
                        justifyContent={"flex-end"}
                        alignItems={"flex-end"}
                        spacing={1}>
                        <Grid item display={{ xs: 'none', md: 'block' }} md={4} height={"100%"}>
                            <img src={poodle} style={{ maxWidth: "100%", transform: "scaleX(-1)" }} alt="Poodle" />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Grid item>
                                <Typography className="contactText" variant="h4">
                                    Contact Us!
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className="contactText" variant="h6" marginTop={"20px"}>
                                    Contact Howl At The Moon for service inquiries, pricing, to request a booking, or any other inquiries.
                                </Typography>
                                <Typography className="contactText" align="center" variant="h6" marginTop={"20px"}>
                                    {"Call or Text: "}<a href="tel:256-777-2357" className="mailLink">{"(256) 777-2357"}</a>
                                </Typography>
                                <Typography className="contactText" align="center" variant="h6" marginTop={"5px"}>
                                    {"E-Mail: "}
                                    <a href="mailto:info@hatmgrooming.com" className="mailLink">
                                        {"info@hatmgrooming.com"}
                                    </a>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid >
    );
}